<template>
    <v-dialog
        :value="openDialog"
        width="500"
        @click:outside="closeDialog"
    >
        <v-card>
            <v-card-title class="card-wrapper">
                <div class="text-with-divider">Import {{ entityLabel }} from file</div>
                <div class="card-intent-create">
                    <v-file-input
                        v-model="file"
                        show-size
                        label="Select file"
                        accept=".csv"
                    />
                </div>
            </v-card-title>
            <v-card-actions>
                <div v-if="file && this.clickedImport">
                    <v-progress-circular
                        v-if="successfullImport === null "
                        :size="40"
                        color="primary"
                        indeterminate
                    />
                    <div v-else-if="successfullImport === true">
                        <v-icon :size="40" dark color="green">mdi-checkbox-marked-circle</v-icon> Imported
                    </div>
                    <div v-else-if="successfullImport === false">
                        <v-icon :size="40" dark color="red">mdi-close-thick</v-icon> Something Went Wrong
                    </div>
                </div>
                <div v-else>
                    <v-btn
                        color="primary"
                        class="import-button"
                        :disabled="!file"
                        @click="onImport"
                    >
                        Import
                    </v-btn>
                    <v-btn
                        color="error"
                        text
                        @click="closeDialog"
                    >
                        Cancel
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            openDialog: {
                type: Boolean
            },
            entityLabel: {
                type: String,
                required: false
            },
            successfullImport: {
                type: Boolean,
                default: () => null
            }
        },
        data() {
            return {
                file: null,
                clickedImport: false
            }
        },
        watch: {
            file: {
                handler() {
                    this.clickedImport = false
                }
            },
            successfullImport: {
                handler(oldval, newval) {
                    if (newval) {
                        this.closeDialog()
                    }
                }
            }
        },
        methods: {
            onImport() {
                this.$emit('file-import', this.file)
                this.clickedImport = true
            },
            closeDialog() {
                this.$emit('close-import-dialog')
            }
        }

    }
</script>

<style scoped>
.text-with-divider {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: rgba(0, 0, 0, 0.35);
    margin: 8px 0px;
    width: 100%;
}
.text-with-divider:before,
.text-with-divider:after {
    content: "";
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.35);
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 8px;
}

.card-intent-create {
    width: 100%;
}

.import-button {
    justify-self: center;
}

.v-card__actions {
    display: flex;
    justify-content: center;
}
</style>
